.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bg-dark-teal{
  background-color: #02525f!important;
  color: white;
}
.nav-tabs .nav-link {
  color: white;
}

.bg-dark-teal {
  background-color: #005f73;
  padding: 20px 0;
}

.text-white {
  color: white;
}

.card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  max-width: 80px;
  height: auto;
  margin: 20px 0;
}
.slider-container {
  position: relative;
  max-width: 100%;
  margin: auto;
}

.slider {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.slider img {
  width: 100%;
  display: block;
  transition: transform 0.5s ease-in-out;
}

.slider-button {
  position: absolute;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  transform: translateY(-50%);
  z-index: 100;
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.text-muted {
  color: #6c757d;
}

.list-group-item {
  padding: 1rem 1.25rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  background-color: #fff;
}

.list-group-item strong {
  font-weight: 700;
}
.wider-card.card {
  background-color: #f7F7F7; /* Светло-серый фон */
  border: none; 
  padding: 16px; /* Отступ внутри карточки */
  border-radius: 8px; /* Скругляем углы */
  min-height: 200px; /* Минимальная высота карточки */
}

.wider-card-title {
  font-size: 24px; /* Размер заголовка */
  font-weight: bold;
  color: #000;
  margin-bottom: 10px; /* Отступ между заголовком и текстом */
  text-align: left; /* Выравнивание текста по левому краю */
}

.wider-card-description {
  font-size: 16px; /* Размер текста описания */
  color: #000; /* Цвет текста описания */
  text-align: left; /* Выравнивание текста по левому краю */
  line-height: 1.6; /* Увеличиваем межстрочный интервал */
}