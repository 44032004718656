.slider-container {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    background-color: none;
    
}

.slider {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider img {
    max-width: 100%;
    border-radius: 5px;
    background-size: contain;
    transition: opacity 0.7s ease-in-out;
    
    height: 300px;
}

.slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.slider-button.prev {
    left: 10px;
}

.slider-button.next {
    right: 10px;
}

.slider-button:hover {
    background-color: rgba(255, 255, 255, 1);
}

.slider p {
    margin-top: 10px;
    font-size: 1rem;
    text-align: center;
    color: #555;
}
